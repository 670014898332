// Event names MUST end with the word EVENT

export const BUTTON_CLICK_EVENT = 'BUTTON_CLICK_EVENT';
export const TAB_CLICK_EVENT = 'TAB_CLICK_EVENT';
export const LINK_CLICK_EVENT = 'LINK_CLICK_EVENT';
export const LISTITEM_CLICK_EVENT = 'LISTITEM_CLICK_EVENT';
export const DROPDOWN_CHANGE_EVENT = 'DROPDOWN_CHANGE_EVENT';
export const WINDOW_OPEN_EVENT = 'WINDOW_OPEN_EVENT';
export const KEYBOARD_COPY_EVENT = 'KEYBOARD_COPY_EVENT';
export const AUTH_REQUEST_EVENT = 'AUTH_REQUEST_EVENT';
