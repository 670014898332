export const shareQueryStyles = () => {
  return {
    iconButton: {
      root: {
        float: 'right',
        border: '1px solid',
        width: '100%'
      }
    }
  }
}